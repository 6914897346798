.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.multiSelect.p-multiselect {
  border-color: rgb(218, 220, 224);
  border-radius: 10px
}

.multiSelect.p-dropdown {
  border-color: rgb(218, 220, 224);
  border-radius: 10px;
  width: 35%
}

.multiSelect.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: white !important;
}

.chipItems.p-chip {
  background: aliceblue;
  border: solid 0.5px #acb8c2;
}

.chipItems.p-chip .p-chip-text {
  line-height: 1 !important;
  font-size: 14px;
}

.inputText.p-inputtext {
  border-color: rgb(218, 220, 224);
  padding-top: 9px;
  padding-bottom: 10px;
  border-radius: 10px;
  width: 100%
}

.globalFilter.p-dropdown {
  border-color: rgb(218, 220, 224);
  border-radius: 30px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  margin-right: 1%;
}

.globalFilter.p-dropdown .p-dropdown-label {
  /* background: #2d4d76; */
  background: #ebf5fc;
  color: #2d4d76;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  font-size: 15px;
  font-family: 'Karla';
  font-weight: bold;
  letter-spacing: 0.2px;
}

.globalFilter.p-dropdown .p-dropdown-label.p-placeholder {
  color: #2d4d76;
}

.globalFilter.p-dropdown .p-dropdown-trigger {
  background: #ebf5fc;
  color: #2d4d76;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.globalFilter.p-dropdown-trigger-icon p-clickable pi pi-chevron-down {
  font-size: 14px;
}

.multiSelectDropdown.p-multiselect-panel.p-component.p-connected-overlay-enter-done {
  z-index: 3003 !important;
  border-radius: 10px;
}

.multiSelectPanel.p-multiselect {
  border: solid 1px lightgray;
  border-radius: 5px;
  padding: 4px;
  font-family: 'Karla';
}

.multiSelectPanel.p-multiselect.p-multiselect-chip.p-multiselect-token {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 15px;
}

.selectButton.p-selectbutton .p-button {
  border-color: rgb(218, 220, 224);
  border-radius: 15px;
  padding: 7px 10px 7px 10px;
  font-size: 15px;
  height: 30px;
}

.selectButton.p-selectbutton .p-button.p-highlight {
  border-color: rgb(218, 220, 224);
  color: #2d4d76;
  background: aliceblue;
  padding: 7px 10px 7px 10px;
  font-size: 15px;
  height: 30px;
}

.splitterPanel.p-splitter-panel {
  flex-basis: calc(70% - 0px);
}

.splitPanel.p-splitter.p-component {
  font-family: 'Karla';
}

.tabViewPanel.p-tabview .p-tabview-panels {
  padding: 0px
}

.dataTable.p-datatable-header {
  background: #f8f9fa;
}

.table-mt-15.p-datatable .p-datatable-wrapper {
  margin-top: 15px;
}

.dataTable.p-datatable .p-datatable-tbody>tr>td {
  word-break: break-word;
  font-family: 'Karla';
  font-size: 13px;
  /* padding: 3px; */
}

.dataTable.p-datatable .p-datatable-thead>tr>th {
  word-break: break-word;
  font-family: 'Karla';
  font-size: 13px;
}

.dataTable.p-datatable .p-sortable-column .p-sortable-column-icon {
  font-size: 12px;
}

.dataTableAsList.p-datatable .p-datatable-tbody>tr>td {
  word-break: break-word;
  font-family: 'Karla';
  font-size: 13px;
}

.dataTableAsList.p-datatable .p-datatable-thead>tr>th {
  word-break: break-word;
  font-family: 'Karla';
  font-size: 13px;
}

.dialog.p-dialog .p-dialog-header {
  padding: 15px 5px 10px 5px;
  background: aliceblue;
}

.progressBar.p-progressbar {
  height: 6px;
}

.imageCarousel.p-carousel {
  width: 100%;
}

.organizationchart.p-organizationchart .p-organizationchart-node-content {
  padding: 0em;
  width: 140px;
  border: 0 none;
}

.organizationchart.p-organizationchart .node-header {
  background-color: #495ebb;
  color: #ffffff;
}

.organizationchart.p-organizationchart .node-content {
  border: 0 none;
  padding: 0em;
}

/* .organizationchart.p-organizationchart .p-organizationchart-line-down {
  color: #454546;
  border-right: 1px solid #9999a0;
  border-color: #454546;
} */

/* .organizationchart.p-organizationchart .p-organizationchart-line-top {
  color: #454546;
  border-right: 1px solid #454546;
  border-color: #9999a0;
} */

/* .organizationchart.p-organizationchart .p-organizationchart-line-left {
  color: #454546;
  border-right: 1px solid #454546;
  border-color: #9999a0;
}

.organizationchart.p-organizationchart .p-organizationchart-line-right {
  color: #454546;
  border-right: 1px solid #454546;
  border-color: #9999a0;
} */

.p-button.p-button-info,
.p-buttonset.p-button-info>.p-button,
.p-splitbutton.p-button-info>.p-button,
.p-fileupload-choose.p-button-info {
  color: #ffffff;
  background: #0050B3;
  padding: 6px 6px;
  border: 1px solid #0050B3;
  font-size: 13px;
  font-family: 'Karla';
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.2px;
}

.p-menu .p-menuitem-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  font-size: 13px;
  font-weight: bold;
}

.p-menu.p-menu-overlay {
  background: #ffffff;
  max-width: 150px;
  border: 0 none;
  height: 500px;
  overflow: auto;
}


.p-autocomplete-panel.p-component.p-connected-overlay-enter-done {
  background: #ffffff;
  max-width: 150px;
  border: 0 none;
  height: 500px;
  overflow: auto;
  z-index: 9999 !important;
}

.p-button.p-button-icon-only {
  width: 30px;
}

.p-multiselect-panel.p-component.p-connected-overlay-enter-done {
  z-index: 9999!important;
}

.MuiDialog-paperScrollPaper{
  overflow-y: visible!important;
}

.MuiDialog-paperScrollPaper .MuiDialogContent-root{
.p-button.p-component.p-highlight {
  background: #2d4d76 !important;
  border-color: #2d4d76 !important;
  color: #ffffff !important;
}
}